<template>
    <div>
        <CustomDataTable :table-title="tableTitle" :params="params" :filters="filters" :table-fields="tableFields" v-on:show-data-modal="showDataRowModal" ref="table" :buttons="buttons"></CustomDataTable>
        <DataRowModal :dialog="dialog" :data="rowData" @hide-data-modal="hideDataRowModal" type="exp" @delete-data-row-exp="deleteDataRow" @resync-exp="reSyncExp" :fields="rowFields"></DataRowModal>
    </div>
</template>

<script>
    //import Vue from 'vue';
    import CustomDataTable from "../../../components/table/CustomDataTable";
    import DataRowModal from "../../../components/table/DataRowModal";
    import Vue from "vue";
    export default {
        name: "EXPs_view",
        components: {DataRowModal, CustomDataTable},
        data: ()=>({
            tableTitle: 'Bangladesh Bank EXPs',
            params:{
                Type: 'exp',
                Skip: 0,
                Take: 10,
            },
            filters:[
                { key: 'ExpNo', label: 'EXP', value: null, type: 'number' },
                { key: 'DateFrom', label: 'Date From', value: null, type: 'date' },
                { key: 'DateTo', label: 'Date From', value: null, type: 'date' },
                { key: 'Bin', label: 'Bin', value: null, type: 'number' },
                { key: 'InvoiceNo', label: 'Invoice', value: null, type: 'number' },
                { key: 'BlNo', label: 'BL No', value: null, type: 'string' },
                { key: 'ErcName', label: 'Exporter', value: null, type: 'string' },
            ] ,
            tableFields: [
                {key: 'Id', label: 'ID', type: 'number', searchable: false},
                {key: 'ExpNo', label: 'Exp No', type: 'number', searchable: true},
                {key: 'IssueDate', label: 'Issue Date', type: 'date', searchable: false},
                {key: 'ErcName', label: 'Exporter', type: 'string', searchable: true},
                {key: 'Bin', label: 'Bin', type: 'number', searchable: true},
                {key: 'InvAmount', label: 'Invoice Value', type: 'number', searchable: false},
                {key: 'BlNo', label: 'BL No', type: 'string', searchable: false},
                {key: 'CountryIso', label: 'Country', type: 'countryIso', searchable: false},
                {key: 'CurrencyIso', label: 'Currency', type: 'string', searchable: false},
            ],

            dialog: false,
            rowData: {},
            rowFields: [
                {key: 'Id', label: 'ID', hasChild: false, type: 'string'},
                {key: 'ExpNo', label: 'Exp No', hasChild: false, type: 'string'},
                {key: 'ErcNo', label: 'Exporter No', hasChild: false, type: 'string'},
                {key: 'ErcName', label: 'Exporter', hasChild: false, type: 'string'},
                {key: 'Bin', label: 'Bin No', hasChild: false, type: 'string'},
                {key: 'InvAmount', label: 'Invoice Amount', hasChild: false, type: 'string'},
                {key: 'CurrencyIso', label: 'Currency', hasChild: false, type: 'string'},
                {key: 'CmtAmount', label: 'CmtAmount', hasChild: false, type: 'string'},
                {key: 'CountryIso', label: 'Country', hasChild: false, type: 'string'},
                {key: 'TosNo', label: 'Invoice No', hasChild: false, type: 'string'},
                {key: 'TosDate', label: 'Invoice Date', hasChild: false, type: 'date'},
                {key: 'TosType', label: 'Invoice Type', hasChild: false, type: 'string'},
                {key: 'ShipPort', label: 'Ship Port', hasChild: false, type: 'string'},
                {key: 'DestPort', label: 'Destination Port', hasChild: false, type: 'string'},
                {key: 'ImporterInfo', label: 'Importer', hasChild: false, type: 'string'},
                {key: 'BlType', label: 'BL Type', hasChild: false, type: 'string'},
                {key: 'BlNo', label: 'BL No', hasChild: false, type: 'string'},
                {key: 'BlDate', label: 'BL Date', hasChild: false, type: 'date'},
                {key: 'ShipmentDate', label: 'Shipment Date', hasChild: false, type: 'date'},
                {key: 'CustomOffice', label: 'Custom Office', hasChild: false, type: 'string'},
                {key: 'CustomBillNo', label: 'Custom Bill No', hasChild: false, type: 'string'},
                {key: 'CustomBillDate', label: 'Custom Bill Date', hasChild: false, type: 'date'},
                {key: 'IssueDate', label: 'Issue Date', hasChild: false, type: 'date'},
                {
                    key: 'ExpCommodityModels', label: 'HS Codes', hasChild: true, type: 'array',
                    childFields: [
                        {key: 'CommodityCode', label: 'HS Code', type: 'string'},
                        {key: 'Quantity', label: 'Qty', type: 'string'},
                        {key: 'UnitIso', label: 'Unit', type: 'string'},
                    ]
                },
            ],
            buttons: []
        }),
        methods:{
            showDataRowModal: function (payload) {
                console.log(payload);
                if(payload.type === 'exp') {
                    this.rowData = payload.data;
                    this.dialog = true;
                }
            },
            hideDataRowModal: function () {
                this.dialog = false;
            },
            deleteDataRow: async function (data) {
                if (confirm("Are you sure that you want to Delete this item?") == true) {
                    this.dialog = false;

                    await this.$axios.post(Vue.prototype.$globalSettings.api.endpoints.action.deleteData, {
                        Type: this.params.Type,
                        ExpId: data.Id,
                        SBId: null
                    })
                        .then(async (response) => {
                            console.log(response.data);
                            if (response.data.Status === 'success') {
                                await this.$refs.table.loadTableData();
                            }
                        })
                        .catch((error) => {
                            this.$globalHelpers.processHttpErrorsToast(error);
                        })
                }
            },
            reSyncExp: async function (data) {
                if (confirm("Are you sure that you want to ReSync this item?") == true) {
                    await this.$axios.get(Vue.prototype.$globalSettings.api.endpoints.action.resyncExp, {params: {Id: data.Id}})
                        .then(async (response) => {
                            console.log(response.data);
                            if (response.data.Status === 'success') {
                                await this.$refs.table.loadTableData();
                                this.dialog = false;
                            }
                        })
                        .catch((error) => {
                            this.$globalHelpers.processHttpErrorsToast(error);
                        })
                }
            }
        },
        created() {

        }
    }
</script>

<style scoped>

</style>